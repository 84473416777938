import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { SessionService } from '../session-service/session.service';
export interface ResourceStatus {
  base_comp: String,
  base_date: String,
  time_left: number,
  percentage: number,
}
@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  public resource_status: ResourceStatus = {
    base_comp: '',
    base_date: '',
    time_left: 0,
    percentage: 0,
  }

  public CREATE_RESOURCE = gql`
mutation CreateResource($data: ResourceInput!) {
  createResource(data: $data) {
    ... on Resource {
      id
      regionCode
      resourceCode
      name
      maxPrice
      lifeTime
    }
    ... on ResourceStatus {
      code
      status
      message
      data {
        id
        regionCode
        resourceCode
        name
        maxPrice
        lifeTime
      }
    }
  }
}
`

  public GET_RESOURCES = gql`
query GetResources($regionCode: Float!) {
  getResources(regionCode: $regionCode) {
    ... on Resources {
      result {
        id
        regionCode
        resourceCode
        name
        maxPrice
        src
        lifeTime
      }
    }
    ... on ResourceStatus {
      code
      status
      message
      data {
        id
        regionCode
        resourceCode
        name
        maxPrice
        lifeTime
      }
    }
  }
}
`;


  public UPDATE_RESOURCE = gql`
mutation UpdateResource($resourceCode: String!, $data: ResourceUpdateInput!) {
  updateResource(resourceCode: $resourceCode, data: $data) {
    ... on Resource {
      id
      regionCode
      resourceCode
      name
      maxPrice
      lifeTime
    }
    ... on ResourceStatus {
      code
      status
      message
      data {
        id
        regionCode
        resourceCode
        name
        maxPrice
        lifeTime
      }
    }
  }
}

`

  public DELETE_RESOURCE = gql`
mutation DeleteResource($resourceCode: String!) {
  deleteResource(resourceCode: $resourceCode) {
    code
    status
    message
    data {
      id
      regionCode
      resourceCode
      name
      maxPrice
      lifeTime
    }
  }
}

`

  constructor(private apollo: Apollo, private sessionservice: SessionService) { }

  getResources() {


    return this.apollo
      .watchQuery<any>({
        query: this.GET_RESOURCES,
        variables: {

          regionCode: 0,

        },
        fetchPolicy: "no-cache"

      })


  }

  async getResourcesToPromise() {


    var result= await this.apollo
      .query<any>({
        query: this.GET_RESOURCES,
        variables: {

          regionCode: 0,

        },
        fetchPolicy: "cache-first"

      }).toPromise()

      return result?.data?.getResources?.result


  }



  createResource(data) {
    return this.apollo
      .mutate<any>({
        mutation: this.CREATE_RESOURCE,
        variables: {
          data: data

        }


      })
  }
  updateResource(resourceCode, data) {
    return this.apollo
      .mutate<any>({
        mutation: this.UPDATE_RESOURCE,
        variables: {
          resourceCode: resourceCode.toString(),
          data: data

        }


      })
  }

  deleteResource(resourceCode) {
    return this.apollo
      .mutate<any>({
        mutation: this.DELETE_RESOURCE,
        variables: {
          resourceCode: resourceCode.toString(),


        }


      })
  }

  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }
  timeDiff(dt2, dt1) {

    var date2 = this.convertTZ(dt2, 'Africa/Johannesburg');
    // //console.log(dt1);
    var date1 = this.convertTZ(dt1, 'Africa/Johannesburg');



    var diff = (date2.getTime() - date1.getTime()) / 1000;


    return diff;

  }
  getResourceStatus(base_date, base_comp) {

    var time_diff = this.timeDiff(new Date(), base_date);
    // //console.log(time_diff)
    //var base_comp = parseInt(this.user_resources[i].base_comp);
    var base_comp_seconds = base_comp * 3600;
    if (time_diff >= base_comp_seconds) {
      var excedeed = Math.floor(time_diff / base_comp_seconds);
      var percent = (excedeed >= 3) ? 25 : 100 - excedeed * 25;
      // //console.log(excedeed);
      // //console.log(percent)
      /*
        6-(14-6*2)

        4/7
        50%
        6/14 
        14-6=8
        
6/14=8

        7-4=3
        7-3

      */
      let diff_exc = base_comp - excedeed;
      let time_l = (base_comp - ((time_diff / 3600) - base_comp * excedeed)) * 3600;
      // alert(time_l);
      (percent == 25) ? this.resource_status = {
        base_comp: base_comp,
        base_date: base_date,
        time_left: 0,
        percentage: percent,
      } :


        this.resource_status = {
          base_comp: base_comp,
          base_date: base_date,
          time_left: time_l,
          percentage: percent,
        }
      return this.resource_status;
    }
    else {

      return this.resource_status = {
        base_comp: base_comp,
        base_date: base_date,
        time_left: base_comp_seconds - time_diff,
        percentage: 100,
      }

    }
    /*
      1.Calcular o tempo passado 
      2.verificar se ultrapassou o base_comp
      3.Caso tenha ultrapassado faz-se a subtraccao por 25 se a divisao do tempo excedido pelo base_comp nao for maior que 25
      3.1.Caso seja a percentagem sera igual a 25 e o tempo sera reiniciado tendo em conta o base_comp.
      3.2 Caso contrario a percentagem sera o resultado da subtracao de 100  pela multiplicacao entre o tempo excedido por 25
      e o tempo sera reiniciado tendo em conta o base_comp.
      4.Caso nao se tenha ultrapasado o base_comp retorna-se o tempo restante e a percentagem sera de 100%
    */
  }

}
