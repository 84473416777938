<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo text-green" href="#" (click)="navigateHome()"><img class="n"
        style="width: 50px;height: 50px;" src="assets/images/app/dclogoblack.png" alt="" srcset=""></a>
  </div>

</div>

<div id="header" class="header-container">
  <nb-actions size="small">



    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="name" title="{{category}}"
        [picture]="'assets/images/User/user.png'">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>