import { Component } from '@angular/core';
import { UserachievementComponent } from 'app/pages/userachievement';


@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header  fixed >
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
    
      <ng-content select="nb-menu"></ng-content>
    
      </nb-sidebar>

      <nb-layout-column>
       
           <div style="margin-top: 0em;" id="content" >
            <ng-content  select="router-outlet"></ng-content>
          </div> 
      </nb-layout-column>

   
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {

}
