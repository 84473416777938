import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SessionService } from 'app/services/session-service/session.service';
import { AuthService } from 'app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { T } from '@angular/cdk/keycodes';
import { TokenStoreService } from 'app/services/tokenstore/tokenstore.service';
//import { clearInterval, setInterval } from 'timers';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  image = "assets/images/resources/silver2.png"
  name;
  category;



  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  sessionTimeLeft = 5
  sessionInterval

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    public router: Router,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private tokenstore:TokenStoreService,
    private breakpointService: NbMediaBreakpointsService, private authservice: AuthService, private sessionservice: SessionService) {
  }

  ngOnInit() {
    this.name = this.sessionservice.getUserData('firstName') + ' ' + this.sessionservice.getUserData('lastName');
    this.category = 'Agent'
    this.setTokenData()
    this.sessionTimer()
    // //console.log(window.localStorage.getItem('first-name'))
    // this.lastname=this.sessionservice.getUserData('last-name')
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title === 'Log out') {
        this.authservice.logout()
      }
      if (event.item.title === 'Profile') {
        this.router.navigate(['pages/profile']);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setTokenData(){
  /*  this.tokenstore.getTokenstore().valueChanges.subscribe(({ data }) => {

      // console.log(data)
      if (data.getTokensStore.status) {
       
        return false
      }

      //  console.log(data.getTokenstore)
      //console.log(data.getTokenStores.result)
     var tokens = data.getTokensStore.result
     var index=tokens.findIndex(token=>token.code=="1"||token.code==1)
     if(index>=0){
      window.localStorage.setItem("tokenData",JSON.stringify(tokens[index]))
     }
    

    }, err => {
     console.log("ERROR GETTING TOKENS",err)

    })*/
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  async sessionTimer() {
    await this.authservice.getTokenTimeLeft().then(({ data }) => {
      //  if (data.getTokenStatus.tokenTimeLeft == 0) {
      this.sessionTimeLeft = parseInt(data.getTokenStatus.tokenTimeLeft)
      // }
      //console.log('stl',this.sessionTimeLeft)

    })

    this.sessionInterval = setInterval(() => {
      if (this.sessionTimeLeft == 0) {
        this.breakSession()

      }

      if (this.sessionTimeLeft > 0) {
        this.sessionTimeLeft--;
      }

    }, 1000)


  }


  breakSession() {
    clearInterval(this.sessionInterval)
    this.authservice.logout()
  }

}


