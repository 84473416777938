
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
@Injectable({
  providedIn: 'root'
})
export class TokenStoreService {

  public CREATE_TOKEN_STORE = gql`
mutation CreateTokenStore($data: TokenStoreInput!) {
  createTokenStore(data: $data) {
    code
    status
    message
    data {
      id
      code
      price
      qtyMin
    }
  }
}
`

  public GET_TOKEN_STORE = gql`
query GetTokensStore($regionCode: Float) {
  getTokensStore(regionCode: $regionCode) {
    result {
      id
      code
      price
      qtyMin
    }
  }
}
`

  public UPDATE_TOKEN_STORE = gql`
mutation UpdateTokenStore($code: String!, $data: TokenStoreInputUpdate!) {
  updateTokenStore(code: $code, data: $data) {
    code
    status
    message
    data {
      id
      code
      price
      qtyMin
    }
  }
}
`

  public DELETE_TOKEN_STORE = gql`
mutation DeleteTokenStore($code: String!) {
  deleteTokenStore(code: $code) {
    code
    status
    message
    data {
      id
      code
      price
      qtyMin
    }
  }
}
`




  constructor(private apollo: Apollo) {

  }

  createTokenStore(data) {
    // console.log(data)
    return this.apollo
      .mutate<any>({
        mutation: this.CREATE_TOKEN_STORE,
        variables: {
          data: data

        }


      })
  }

  getTokenstore() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_TOKEN_STORE,
        variables: {
          regionCode: 0,
        },
        fetchPolicy: "no-cache"

      })
  }

   getTokenstorePromise() {
    return this.apollo
    .query<any>({
      query: this.GET_TOKEN_STORE,
      variables: {
        regionCode: 0,
      },
      fetchPolicy: "no-cache"

    })
  }

  updateTokenStore(code, data) {
    // console.log(data)
    return this.apollo
      .mutate<any>({
        mutation: this.UPDATE_TOKEN_STORE,
        variables: {
          code: code,
          data: data

        }


      })
  }

  deleteTokenStore(code) {
    // console.log(data)
    return this.apollo
      .mutate<any>({
        mutation: this.DELETE_TOKEN_STORE,
        variables: {
          code: code,


        }

      })
  }


}
