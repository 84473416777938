import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }
  saveSession(userData) {
        //console.log(userData)
  
    
    window.localStorage.setItem('auth-token', userData.token);
    window.localStorage.setItem('firstName', userData.firstName);
    window.localStorage.setItem('lastName', userData.lastName);
    
    

  }
  updatePersonalDataOnSession(data){
    window.localStorage.setItem('firstName', data.firstName);
    window.localStorage.setItem('lastName', data.lastName);
  }
  getUserData(data) {
     return window.localStorage.getItem(data)
     
  }
  removeUserData(){
    window.localStorage.removeItem('auth-token');
    window.localStorage.removeItem('firstName');
    window.localStorage.removeItem('lastName');
    

    
    
  }
  
}
