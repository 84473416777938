import { EventEmitter, Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo, QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';
import { SessionService } from '../session-service/session.service';
import { AuthService } from '../auth-service/auth.service';
import { UserService } from '../user/user.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket'
import { Observable } from 'rxjs';
import { PlacesService } from '../places/places.service';
import { ProductsService } from '../products/products.service';
import { ResourceService } from '../resources/resource.service';
export interface Resource {
  src: String,
  name: String

}
export interface ResourceStatus {
  base_comp: String,
  base_date: String,
  time_left: number,
  percentage: number,
}
export interface AreasNot {
  areaCode: string,
  seen: string,
  notificationId: string

}
export interface LocalStore {
  resourceCode: String,
  src: String,
  name: String,
  available: Boolean
  price: number,
  maxPrice: number,
  discount: number

}
export interface Areas {
  name: String,
  src: String,
  regionCode: String
}
export interface Product {
  product: string
  name: string
}
@Injectable({
  providedIn: 'root'
})
export class DcThemeService {
  notQuery: QueryRef<any>;
  notifyUser: Observable<any>;
  params: any;
  public USER_ALLOWED = gql`
  query userAllowed($id: String!,$regionCode:String!,$action:String) {
    userAllowed(id: $id,regionCode:$regionCode,action:$action) {
     allowed
     message
      
    }
  }
`;
  public NOTIFICATION = gql`
  subscription notify($channel: String) {
    notify(channel: $channel) {
    id
   
      
    }
  }
`;
  public GETNOTIFY = gql`
query{
  testQuery{
    val
  }
}
`;
  public GET_CLIENTID = gql`
query getClientId{
  getClientId{
    clientId
  }
   
  
}
`;
  public GETNOTIFICATION = gql`
query getNotification($id:String,$regionCode:String){
  getNotification(id:$id,regionCode:$regionCode){
    areaCode
    seen
    notificationId
  }
}
`;
  public UPDATESEEN = gql`
mutation updateSeen($id:String,$regionCode:String,$areaCode:String){
  updateSeen(id:$id,regionCode:$regionCode,areaCode:$areaCode){
    status
   
  }
}
`;
  public NOT = gql`
subscription{
  test{
    id
    
  }
}
`;
  public SEND_NOTIFICATION = gql`
mutation sendNotification($id:String,$regionCode:String,$areaCode:String){

  sendNotification(id:$id,regionCode:$regionCode,areaCode:$areaCode){
    id
  }

}
`
  areas: Areas[] = [{
    name: 'Skara Brae',
    src: 'assets/images/areas/skarabrae.png',
    regionCode: '0'
  },
  {
    name: 'Gade',
    src: 'assets/images/areas/gade.png',
    regionCode: '0'
  }
    ,
  {
    name: 'Gwydnno',
    src: 'assets/images/areas/gwydnno.png',
    regionCode: '0'
  },
  {
    name: 'Bulkran',
    src: 'assets/images/areas/bulkran.png',
    regionCode: '0'
  },
  {
    name: 'Heraclon',
    src: 'assets/images/areas/heraclon.png',
    regionCode: '0'
  },
  {
    name: 'Agdam',
    src: 'assets/images/areas/agdam.png',
    regionCode: '0'
  },
  {
    name: 'Gieno',
    src: 'assets/images/areas/gieno.png',
    regionCode: '1'

  },
  {
    name: 'Salon',
    src: 'assets/images/areas/salon.png'
    ,
    regionCode: '1'
  },
  {
    name: 'Deju',
    src: 'assets/images/areas/deju.png'
    ,
    regionCode: '1'
  },
  {
    name: 'Urkesh',
    src: 'assets/images/areas/urkesh.png'
    ,
    regionCode: '1'
  },
  {
    name: 'Satungira',
    src: 'assets/images/areas/satungira.png'
    ,
    regionCode: '1'
  },
  {
    name: 'Parangari',
    src: 'assets/images/areas/parangari.png'
    ,
    regionCode: '1'
  },
  {
    name: 'Caral',
    src: 'assets/images/areas/caral.png'
    ,
    regionCode: '2'
  },
  {
    name: 'Tevy',
    src: 'assets/images/areas/tevy.png'
    ,
    regionCode: '2'
  },
  {
    name: 'Or',
    src: 'assets/images/areas/or.png'
    ,
    regionCode: '2'
  },
  {
    name: 'Polyana',
    src: 'assets/images/areas/polyana.png',
    regionCode: '2'
  },
  {
    name: 'Saua',
    src: 'assets/images/areas/saua.png'
    ,
    regionCode: '2'
  },

  {
    name: 'Salsa',
    src: 'assets/images/areas/salsa.png'
    ,
    regionCode: '2'
  },
  {
    name: 'Ovlin',
    src: 'assets/images/areas/ovlin.png'
    ,
    regionCode: '3'
  },
  {
    name: 'Balestino',
    src: 'assets/images/areas/balestino.png'
    ,
    regionCode: '3'
  },
  {
    name: 'Michoacan',
    src: 'assets/images/areas/michoacan.png'
    ,
    regionCode: '3'
  },
  {
    name: 'Kayakoy',
    src: 'assets/images/areas/kayakoy.png'
    ,
    regionCode: '3'
  },
  {
    name: 'Chaten',
    src: 'assets/images/areas/chaten.png'
    ,
    regionCode: '3'
  },
  {
    name: 'Pontalucy',
    src: 'assets/images/areas/pontalucy.png'
    ,
    regionCode: '3'
  },
  {
    name: 'Olik',
    src: 'assets/images/areas/olik.png'
    ,
    regionCode: '4'
  },
  {
    name: 'Carthage',
    src: 'assets/images/areas/carthage.png'
    ,
    regionCode: '4'
  },
  {
    name: 'Taxila',
    src: 'assets/images/areas/taxila.png'
    ,
    regionCode: '4'
  },
  {
    name: 'Jahaz Mahal',
    src: 'assets/images/areas/jahazmahal.png'
    ,
    regionCode: '4'
  },
  {
    name: 'Heike',
    src: 'assets/images/areas/heike.png'
    ,
    regionCode: '4'
  },
  {
    name: 'Transvel',
    src: 'assets/images/areas/transvel.png'
    ,
    regionCode: '4'
  }
  ];
  public resource: Resource[] = [{ src: 'assets/images/resources/copper.png', name: "Copper" },
  { src: 'assets/images/resources/bronze.png', name: "Bronze" },
  { src: 'assets/images/resources/silver2.png', name: "Silver" },
  { src: 'assets/images/resources/gold2.png', name: "Gold" },
  { src: 'assets/images/resources/patinum2.png', name: "Platinum" },
  { src: 'assets/images/resources/diamond.png', name: "Diamond" },
  { src: 'assets/images/resources/rubi2.png', name: "Ruby" },
  ]
  public products: Product[] = [
    { product: '<img class ="img-valign"src="assets/images/products/electric_wire.png" />', name: 'Electric wire' },
    { product: '<img class ="img-valign"src="assets/images/products/bronze_earrings.png" />', name: 'Bronze earrings ' },
    { product: '<img class ="img-valign"src="assets/images/products/silver_earring.png" />', name: 'Silver earrings ' },
    { product: '<img class ="img-valign"src="assets/images/products/gold_earring.png" />', name: 'Gold earrings ' },
    { product: '<img class ="img-valign"src="assets/images/products/bronze_trophy.png" />', name: 'Bronze trophy ' },
    { product: '<img class ="img-valign"src="assets/images/products/silver_trophy.png" />', name: 'Silver trophy' },
    { product: '<img class ="img-valign"src="assets/images/products/silver_bronze_medal.png" />', name: ' Silver bronze medal' },
    { product: '<img class ="img-valign"src="assets/images/products/silver_bronze_trophy.png" />', name: ' Silver bronze trophy' },
    { product: '<img class ="img-valign"src="assets/images/products/gold_bracelete.png" />', name: ' Gold bracelete' },
    { product: '<img class ="img-valign"src="assets/images/products/gold_trophy.png" />', name: 'Gold trophy ' },
    { product: '<img class ="img-valign"src="assets/images/products/olimpyc_trophy.png" />', name: 'Olympic trophy ' },
    { product: '<img class ="img-valign"src="assets/images/products/ocean_ring.png" />', name: 'Ocean ring ' },
    { product: '<img class ="img-valign"src="assets/images/products/platinum_heart.png" />', name: ' Platinum hearth' },
    { product: '<img class ="img-valign"src="assets/images/products/platinum_wedding.png" />', name: 'Platinum wedding ' },
    { product: '<img class ="img-valign"src="assets/images/products/gold_ring.png" />', name: ' Gold ring' },
    { product: '<img class ="img-valign"src="assets/images/products/diamond_wire.png" />', name: ' Diamond wire' },
    { product: '<img class ="img-valign"src="assets/images/products/engadgment_diamond.png" />', name: ' Engadgment diamond' },
    { product: '<img class ="img-valign"src="assets/images/products/mrs_rubi.png" />', name: ' Daimruby' },
    { product: '<img class ="img-valign"src="assets/images/products/gazziny.png" />', name: 'Gazziny ' },
    { product: '<img class ="img-valign"src="assets/images/products/master_t.png" /><h5 class="  img-text">', name: ' Master treasure' },
  ];
  public localStore: LocalStore[] = [{ resourceCode: "0", src: 'assets/images/resources/copper.png', name: "Copper", available: false, price: 909, maxPrice: 909, discount: 0 },
  { resourceCode: "1", src: 'assets/images/resources/bronze.png', name: "Bronze", available: false, price: 909, maxPrice: 909, discount: 0 },
  { resourceCode: "2", src: 'assets/images/resources/silver2.png', name: "Silver", available: false, price: 909, maxPrice: 909, discount: 0 },
  { resourceCode: "3", src: 'assets/images/resources/gold2.png', name: "Gold", available: false, price: 909, maxPrice: 909, discount: 0 },
  { resourceCode: "4", src: 'assets/images/resources/patinum2.png', name: "Platinum", available: false, price: 909, maxPrice: 909, discount: 0 },
  { resourceCode: "5", src: 'assets/images/resources/diamond.png', name: "Diamond", available: false, price: 909, maxPrice: 909, discount: 0 },
  { resourceCode: "6", src: 'assets/images/resources/rubi2.png', name: "Ruby", available: false, price: 909, maxPrice: 909, discount: 0 },
  ]
  allowed: EventEmitter<Boolean> = new EventEmitter(false);
  discount: EventEmitter<any> = new EventEmitter();
  updateStat = new EventEmitter();
  updateIntStore = new EventEmitter();
  updateNotification = new EventEmitter();
  testUsdata = new EventEmitter();
  subsUpdateInStore: Subscription;
  subsNotityInt: Subscription;
  subsNotification: Subscription;
  subsVar: Subscription;
  updateAreas: EventEmitter<AreasNot[]> = new EventEmitter;
  public disc;
  public resource_status: ResourceStatus = {
    base_comp: '',
    base_date: '',
    time_left: 0,
    percentage: 0,
  }
  constructor(private placesservice: PlacesService, private resourceservice: ResourceService, private productservice: ProductsService, private toastrService: NbToastrService, private apollo: Apollo, private sessionservice: SessionService) {
    // async results


  }

  getResource(index) {
    return this.resource[index];
  }
  
  setDisctount(value) {
    // alert(value)
    this.discount.emit(value);
    this.disc = value;
  }
  checkUserPermissions() {
    // this.authservice.checkToken();

    return this.apollo
      .watchQuery<any>({
        query: this.USER_ALLOWED,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),
          action: 'intermediate',
        },
      })


  }
  public generateUUID  () {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const objectId = timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
      return Math.floor(Math.random() * 16).toString(16);
    }).toLowerCase();

    return objectId;
  }
  calculateRemainingTimeV2(startDate, duration, durationUnit, remainingUnit) {
    const startDateTime = new Date(startDate);
    let durationInMilliseconds;

    const conversionFactors = {
      days: 1000 * 60 * 60 * 24,
      hours: 1000 * 60 * 60,
      minutes: 1000 * 60,
      seconds: 1000,
    };

    if (!conversionFactors[durationUnit]) {
      console.error("Invalid duration unit provided. Use 'minutes', 'hours', or 'days'.");
      return 0;
    }

    durationInMilliseconds = duration * conversionFactors[durationUnit];

    const endDateTime = new Date(startDateTime.getTime() + durationInMilliseconds);
    const remainingTimeInMilliseconds = endDateTime.getTime() - Date.now();

    if (!conversionFactors[remainingUnit]) {
      console.error("Invalid remaining unit provided. Use 'seconds', 'minutes', 'hours', or 'days'.");
      return 0;
    }

    const remainingTime = Math.ceil(remainingTimeInMilliseconds / conversionFactors[remainingUnit]);
    if (remainingTime < 0) {
      return 0
    };

    return (isNaN(remainingTime) ? 0 : remainingTime);
  }
  getClientId() {
    //this.authservice.checkToken();

    return this.apollo
      .watchQuery<any>({
        query: this.GET_CLIENTID,

      })


  }

  async getAreaFromDb(areaCode) {

    var areas = new Array()

    areas = await this.placesservice.getAreasPromise()

    if (areas?.length > 0) {

      var areaIndex = areas.findIndex(area => area.areaCode == areaCode)

      if (areaIndex >= 0) {
        return areas[areaIndex]
      }

      return { name: "", src: "" }

    }


    return { name: "", src: "" }


  }

  async getRegionFromDb(regionCode) {

    var regions = new Array()

    regions = await this.placesservice.getRegionsPromise()

    if (regions?.length > 0) {

      var regionIndex = regions.findIndex(region => region.regionCode == regionCode)

      if (regionIndex >= 0) {
        return regions[regionIndex]
      }

      return { name: "", src: "" }

    }


    return { name: "", src: "" }


  }
  timeDiff(dt2, dt1) {

    var date2 = this.convertTZ(dt2, 'Africa/Johannesburg');
    // console.log(dt1);
    var date1 = this.convertTZ(dt1, 'Africa/Johannesburg');



    var diff = (date2.getTime() - date1.getTime()) / 1000;


    return diff;

  }
  getResourceStatus(base_date, base_comp) {

    var time_diff = this.timeDiff(new Date(), base_date);
    // console.log(time_diff)
    //var base_comp = parseInt(this.user_resources[i].base_comp);
    var base_comp_seconds = base_comp * 3600;
    if (time_diff >= base_comp_seconds) {
      var excedeed = Math.floor(time_diff / base_comp_seconds);
      var percent = (excedeed >= 3) ? 25 : 100 - excedeed * 25;
      // console.log(excedeed);
      // console.log(percent)
      /*
        6-(14-6*2)

        4/7
        50%
        6/14 
        14-6=8
        
6/14=8

        7-4=3
        7-3

      */
      let diff_exc = base_comp - excedeed;
      let time_l = (base_comp - ((time_diff / 3600) - base_comp * excedeed)) * 3600;
      // alert(time_l);
      (percent == 25) ? this.resource_status = {
        base_comp: base_comp,
        base_date: base_date,
        time_left: 0,
        percentage: percent,
      } :


        this.resource_status = {
          base_comp: base_comp,
          base_date: base_date,
          time_left: time_l,
          percentage: percent,
        }
      return this.resource_status;
    }
    else {

      return this.resource_status = {
        base_comp: base_comp,
        base_date: base_date,
        time_left: base_comp_seconds - time_diff,
        percentage: 100,
      }

    }
    /*
      1.Calcular o tempo passado 
      2.verificar se ultrapassou o base_comp
      3.Caso tenha ultrapassado faz-se a subtraccao por 25 se a divisao do tempo excedido pelo base_comp nao for maior que 25
      3.1.Caso seja a percentagem sera igual a 25 e o tempo sera reiniciado tendo em conta o base_comp.
      3.2 Caso contrario a percentagem sera o resultado da subtracao de 100  pela multiplicacao entre o tempo excedido por 25
      e o tempo sera reiniciado tendo em conta o base_comp.
      4.Caso nao se tenha ultrapasado o base_comp retorna-se o tempo restante e a percentagem sera de 100%
    */
  }

  async getResourceFromDb(resourceCode) {

    var resources = new Array()

    resources = await this.resourceservice.getResourcesToPromise()

    if (resources?.length > 0) {

      var resourceIndex = resources.findIndex(resource => resource.resourceCode == resourceCode)

      if (resourceIndex >= 0) {
        return resources[resourceIndex]
      }

      return { name: "", src: "" }

    }


    return { name: "", src: "" }


  }

  async getProductFromDb(productCode) {

    var products = new Array()

    products = await this.productservice.getProductsPromise()

   

    if (products?.length > 0) {

      var productIndex = products.findIndex(product => product.productCode == productCode)
      console.log("Products from productIndex",productIndex)
      if (productIndex >= 0) {
        return products[productIndex]
      }

      return { name: "", src: "" }

    }


    return { name: "", src: "" }


  }

  generateId(){
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const objectId = timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
      return Math.floor(Math.random() * 16).toString(16);
    }).toLowerCase();

    return objectId;
  }
  async getItemByType(itemType, itemCode) {

    switch (itemType) {
      case "RESOURCE":
        var resourceData = await this.getResourceFromDb(itemCode)
        return resourceData
      case "PRODUCT":
        var productData = await this.getProductFromDb(itemCode)
        return productData
      case "TOKEN":
        return { src: "", name: "Token", tokenCode: "" }
      case "TIME":
        return { src: "", name: "Time", code: "" }
      case "TRAVEL":
        return { src: "", name: "Travel", code: "" }
      case "INTERMEDIATE":
        return { src: "", name: "Intermediate", code: "" }
      default:
        return { src: "", name: "", resourceCode: "" }


    }

  }

  refreshStat(val: boolean) {
    this.updateStat.emit(val);
  }
  refreshIntStore(val: string) {
    this.updateIntStore.emit(val);
  }
  refreshIntNotification(val: string) {
    this.updateNotification.emit(val);
  }
  refreshAreas(areas) {
    this.updateAreas.emit(areas)
  }
  updateSeen(areaCode) {
    // alert(order_data.quantity)
    // this.authservice.checkToken();




    return this.apollo
      .mutate<any>({
        mutation: this.UPDATESEEN,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),
          areaCode: areaCode,

        },
        fetchPolicy: 'no-cache',

      })


  }
  getNotification() {

    return this.apollo.watchQuery<any>({
      query: this.GETNOTIFICATION,
      pollInterval: 1000,
      variables: {
        id: this.sessionservice.getUserData('_id'),
        regionCode: this.sessionservice.getUserData('region-code'),

      },
      fetchPolicy: 'no-cache',

    })
  }


  getData() {
    //console.log('suuubs')
    this.NOT = gql`
    subscription{
      test{
        id
        
      }
    }
    `;

    return this.apollo.subscribe({
      query: gql`
      subscription{
        test{
          id
          
        }
      }
      `,
      fetchPolicy: 'no-cache'
    });
  }
  notifyQuery: QueryRef<any>;

  // ... it is the same component as one above


  notify(id, regionCode, areaCode) {

    return this.apollo
      .mutate<any>({
        mutation: this.SEND_NOTIFICATION,
        variables: {
          id: id,
          regionCode: regionCode,
          areaCode: areaCode,

        },


      })

  }

  showToast(position, status, message) {
    this.toastrService.show(
      message,
      'Message!',
      { position, status, preventDuplicates: true });
  }
  getDiscount() {
    return this.disc;
  }
  getProduct(index) {
    // alert(this.products.length)
    return this.products[index]
  }
  isAllowed() {
    return this.allowed
  }
  setPermission(value: Boolean) {
    this.allowed.emit(value)
  }
  getArea(index) {
    return this.areas[index]
  }
  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }
  getTimeLeft(start, criteria) {

    var leftTime
    var end = new Date();
    var startDt = this.convertTZ(start, 'Africa/Johannesburg');
    // //console.log(dt1);
    var endDt = this.convertTZ(end, 'Africa/Johannesburg');
    var seconds = (endDt.getTime() - startDt.getTime()) / 1000;
    //console.log(criteria+'-'+seconds)
    leftTime = criteria - seconds;
    return (leftTime <= 0) ? 0 : leftTime;


  }
  getTimeDiffMinutes(date) {

  }
  getDateTo(date, criteria) {
    var startDt = this.convertTZ(date, 'Africa/Johannesburg');
    var dateTo = startDt.setDate(startDt.getDate() + criteria)
    //console.log(dateTo)
    return dateTo;

  }
}
