
import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { SessionService } from '../session-service/session.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DcThemeService } from '../dc-theme/dc-theme.service';
@Injectable({
  providedIn: 'root'
})
export class PlacesService {


  public GET_REGIONS = gql`
query GetRegions {
  getRegions {
    ... on Regions {
      result {
        id
        name
        regionCode
        cycleData {
          base_date
          base_comp
        }
        shopping {
          resourceCode
          quantity
        }
      }
    }
    ... on RegionStatus {
      code
      status
      message
    }
  }
}
`
  public CREATE_REGION = gql`
mutation CreateRegion($data: RegionInput!) {
  createRegion(data: $data) {
    ... on Region {
      id
      name
      regionCode
      cycleData {
        base_date
        base_comp
      }
      shopping {
        resourceCode
        quantity
      }
    }
    ... on RegionStatus {
      code
      status
      message
    }
  }
}

`
  public UPDATE_REGION = gql`
mutation UpdateRegion($regionCode: Float!, $data: RegionUpdate!) {
  updateRegion(regionCode: $regionCode, data: $data) {
    ... on Region {
      id
      name
      regionCode
      cycleData {
        base_date
        base_comp
      }
      shopping {
        resourceCode
        quantity
      }
    }
    ... on RegionStatus {
      code
      status
      message
    }
  }
}
`

  public DELETE_REGION = gql`
mutation DeleteRegion($regionCode: Float!) {
  deleteRegion(regionCode: $regionCode) {
    code
    status
    message
  }
}
`


  public GET_AREAS = gql`
query getAreas {
getAreas {
  ... on Areas {
    result {
      id
      name
      areaCode
      regionCode
      localStore {
        resourceCode
        price
        lifeTime
        maxPrice
      }
      shopping{
        resourceCode
        quantity
      }
    }
  }
  ... on AreaStatus {
    code
    status
    message
  }
}
}
`;

  public CREATE_AREA = gql`
mutation CreateArea($data: AreaInput!) {
	createArea(data: $data) {
		... on Area {
			id
			name
			areaCode
			regionCode
			localStore {
				resourceCode
				price
				lifeTime
				maxPrice
			}
			shopping {
				resourceCode
				quantity
			}
		}
		... on AreaStatus {
			code
			status
			message
		}
	}
}
`



  public UPDATE_AREA = gql`
mutation UpdateArea($areaCode: Float!, $data: AreaUpdate!) {
  updateArea(areaCode: $areaCode, data: $data) {
    ... on Area {
      id
      name
      areaCode
      regionCode
      localStore {
        resourceCode
        price
        lifeTime
        maxPrice
      }
      shopping {
        resourceCode
        quantity
      }
    }
    ... on AreaStatus {
      code
      status
      message
    }
  }
}
`

  public DELETE_AREA = gql`
mutation DeleteArea($areaCode: Float!) {
  deleteArea(areaCode: $areaCode) {
    code
    status
    message
  }
}

`










  constructor(private apollo: Apollo) {



  }


  getRegions() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_REGIONS,

        fetchPolicy: "no-cache"

      })
  }

  
  async getRegionsPromise() {
    var result= await this.apollo
      .query<any>({
        query: this.GET_REGIONS,

        fetchPolicy: "cache-first"

      }).toPromise()
      return result?.data?.getRegions?.result
  }

  createRegion(data) {
    return this.apollo
      .mutate<any>({
        mutation: this.CREATE_REGION,
        variables: {
          data: data



        }


      })
  }

  updateRegion(data, regionCode) {
    return this.apollo
      .mutate<any>({
        mutation: this.UPDATE_REGION,
        variables: {
          data: data,
          regionCode: regionCode

        }


      })
  }

  deleteRegion(regionCode) {
    return this.apollo
      .mutate<any>({
        mutation: this.DELETE_REGION,
        variables: {

          regionCode: regionCode



        }


      })
  }


  ///////////////////////////////////////////////////////////////////////////////////Areas Block

  getAreas() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_AREAS,

        fetchPolicy: "no-cache"

      })
  }

  async getAreasPromise() {
    var result=await this.apollo
      .query<any>({
        query: this.GET_AREAS,

        fetchPolicy: "cache-first"

      }).toPromise()

      return result?.data?.getAreas?.result
  }

  createArea(data) {
    return this.apollo
      .mutate<any>({
        mutation: this.CREATE_AREA,
        variables: {
          data: data



        }


      })
  }

  updateArea(data, areaCode) {
    return this.apollo
      .mutate<any>({
        mutation: this.UPDATE_AREA,
        variables: {
          data: data,
          areaCode: areaCode

        }


      })
  }

  deleteArea(areaCode) {
    return this.apollo
      .mutate<any>({
        mutation: this.DELETE_AREA,
        variables: {

          areaCode: areaCode

        }


      })
  }


}
