import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { SessionService } from '../session-service/session.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth-service/auth.service';




@Injectable({
  providedIn: 'root'
})
export class UserService {
  public GET_USER_RESOURCES = gql`
  query getUserResources($id: String!,$regionCode:String) {
    getUserResources(id:$id,regionCode:$regionCode) {
      _id
      resourceCode
      base_date
      base_comp
      state
      min
      track{
        areaCode
        regionCode
      }
      
    }
  }
`;
  public GET_USER_TICKETS_AND_MARKET = gql`
  query getUserTicketsAndMarket($id:String,$regionCode:String) {
    getUserTicketsAndMarket(id:$id,regionCode:$regionCode) {
     ticket
     time
     marketing{
       points
       weight
       base_date
       base_comp
     }
      
    }
    

  }
`;
  public GET_USER = gql`
  query getUser($id:String,$regionCode:String) {
    getUser(id:$id,regionCode:$regionCode) {
     firstname
     lastname
     birthdate
     email
     country
     cell
     timeZone
     genre
     category
     userPreferences{
      regionPreferences{
        base_date
        base_date
        regionCode
      }
     }


      
    }
    

  }
`;
  public GET_TRANSFORMATION_DATA = gql`




query TransformationData($id: String,$regionCode:String) {
 

    getUserProducts(id:$id) {
      _id
      productCode
      percentage
      
    }
 
 
  getTransformables(id:$id,regionCode:$regionCode) {
    _id
    resourceCode
    base_date
    base_comp
    state
    
  }
  getDiscount(id:$id,regionCode:$regionCode) {
    percentage  
    
  }
  
  getRegionProducts(regionCode:$regionCode){
    regionCode
    productCode
    name
    price
    combination{
      resourceCode
      quantity
    }
    result{
      resourceCode
      quantity
      base_comp
      state
      

    }
  
  }
}
  




`;
  public GET_USER_ACHIEVEMENTS = gql`
  query getUserAchievement($id: String!) {
  
    getUserTickets(id:$id) {
     ticket
      
    }
    getUserResources(id:$id) {
      _id
      resourceCode
      base_date
      base_comp
      state
      
    }
  }
`;
  public GET_USER_PRODUCTS = gql`
  query getUserProducts($id: String!) {
    getUserProducts(id:$id) {
      productCode
      percentage
      
    }
  }
`;

  public GET_YEAR_REPORT = gql`
query yearReport($id: String,$regionCode:String) {
  yearReport(id:$id,regionCode:$regionCode) {
    amountSales
    services
    amountPurchases
    totalSales
    totalPurchases
    sales{
      value
      total
      time
    }
    purchases{
      value
      total
      time
    }
    resourcesSold{
      resourceCode
      qty
      total
      
    }
    resourcesPurchased{
      resourceCode
      qty
      total
    }
  }
    
  }

`;
  public GET_DAILY_REPORT = gql`
query dailyReport($id: String,$regionCode:String) {
  dailyReport(id:$id,regionCode:$regionCode) {
    amountSales
    amountPurchases
    totalSales
    totalPurchases
    sales{
      value
      total
      time
    }
    purchases{
      value
      total
      time
    }
    resourcesSold{
      resourceCode
      qty
      total
      
    }
    resourcesPurchased{
      resourceCode
      qty
      total
    }
  }
    
  }

`;
  //productCode, id, percentage, regionCode 
  //  transform(productCode:String,id:String,percentage:String,regionCode:String):Status
  //getIntermediateStore
  //  getItem(_, { patternType, patternName, patternItem, regionCode },)
  public GET_INTERMEDIATES_STORE = gql`
query 
getIntermediateStore($id:String,$intermediateId:String,$regionCode:String,$areaCode:String) {
  getIntermediateStore(id:$id,intermediateId:$intermediateId,regionCode:$regionCode,areaCode:$areaCode){
    amountSales
    amountPurchases
    sales 
    purchases
    base_stats_date
    resourcesOnsale{
      resourceId
      resourceCode
      price
      base_comp
      base_date
    }
  }
}
`;
  public GET_TRAVEL_DEFAULTS = gql`
  query travelDefault($regionCode:String,$id:String){

    getTicketDefaults(regionCode:$regionCode) {
      code
      price
      quantity
      
    }
    getDiscount(id:$id,regionCode:$regionCode) {
      percentage  
      
    }
    
    getIntermediatesDefault(id:$id,regionCode:$regionCode){
      criteria
      price
      code
      quantity
    }

  }


`
  /* 
  getItem(patternType:$patternType,patternName:$patternName,patternItem:$patternItem,regionCode:$regionCode){
        patternItem
        value
      }
  
  */

  public GET_TRANSFORMABLES = gql`
query 
getTransformables($id: String!,$regionCode:String) {
  getTransformables(id:$id,regionCode:$regionCode) {
    _id
    resourceCode
    base_date
    base_comp
    state
    
  }
}
`;
  public SOME_PATTERNS = gql`
query 
getItem($patternType:String,$patternName:String,$patternItem:String,$regionCode:String) {
  getItem(patternType:$patternType,patternName:$patternName,patternItem:$patternItem,regionCode:$regionCode){
    patternItem
    value
  }
}
`;

  // getGuests(regionCode:String,areaCode:String,resourceCode:String,id:String):[Guest]
  public RENEW_GUESTS = gql`
  query 
  renewGuests($regionCode:String,$areaCode:String,$resourceCode:String,$id:String) {
    renewGuests(regionCode:$regionCode,areaCode:$areaCode,resourceCode:$resourceCode,id:$id) {
      guestName
      guestId
      
    }
    getSellersbaseDate(regionCode:$regionCode,areaCode:$areaCode,id:$id) {
      base_sellers_date
    }
  }
  `;
  public GET_GUESTS = gql`
query 
getGuests($regionCode:String,$areaCode:String,$resourceCode:String,$id:String) {
  getGuests(regionCode:$regionCode,areaCode:$areaCode,resourceCode:$resourceCode,id:$id) {
    guestName
    guestId
    
  }
  getSellersbaseDate(regionCode:$regionCode,areaCode:$areaCode,id:$id) {
    base_sellers_date
  }
}
`;
  public GET_MORE_42 = gql`
query 
getMore42($regionCode:String,$areaCode:String,$resourceCode:String,$ids:[String]) {
  getMore42(regionCode:$regionCode,areaCode:$areaCode,resourceCode:$resourceCode,ids:$ids) {
    guestName
    guestId
    
  }
}
`;
  public CAPTURE_ORDER = gql`
mutation 
captureOrder($orderID:String) {
  captureOrder(orderID:$orderID) {
   status
    
  }
}
`;
  public UPDATE_PERSONAL_DATA = gql`
mutation 
updatePersonalData($regionCode:String,$id:String,$firstname:String,$lastname:String,$birthdate:String,$country:String,$cell:String,$timeZone:String,$genre:String){
  updatePersonalData(regionCode:$regionCode,id:$id,firstname:$firstname,lastname:$lastname,birthdate:$birthdate,country:$country,cell:$cell,timeZone:$timeZone,genre:$genre){
    status
    message
  }
}
`;
  //updatePersonalData(regionCode:String,id:String,firstname:String,lastname:String,birthdate:String,country:String,cell:String,timeZone:String,genre:String)
  public GET_SELLER_STORE = gql`
query 
getSellerStore($regionCode:String,$areaCode:String,$guestId:String) {
  getSellerStore(regionCode:$regionCode,areaCode:$areaCode,guestId:$guestId) {
    resourcesOnsale{
      resourceId
      resourceCode
      price
      base_comp
      base_date
    }
    guestOrigin{
      areaCode
        regionCode
      }
    
    
  }
}
`;

  public GET_BONUS = gql`
query 
getBonus($owner: String,$regionCode:String) {
  getBonus(owner:$owner,regionCode:$regionCode) {
    description
    _id
    item{
    itemType
    itemCode
    quantity
  }
  owner
  regionCode
    
  }
}
`;
  // addBonusToUser(_, { id, regionCode, bonusId, category })
  public ADD_BONUS_TO_USER = gql`
mutation 
addBonusToUser($id: String,$regionCode:String,$bonusId:String,$category:String) {
  addBonusToUser(id:$id,regionCode:$regionCode,bonusId:$bonusId,category:$category) {
   status
   message
}
}
`;

  // buyResource(areaCode:String,quantity:String,_id:String,resourceCode:String)
  //  transform(productCode:String,id:String,percentage:String,regionCode:String):Status
  public TRANSFORM = gql`
  mutation transform($productCode:String,$id:String,$percentage:String,$regionCode:String,$category:String) {
    transform(productCode:$productCode,id:$id,percentage:$percentage,regionCode:$regionCode,category:$category) {
     status
     message
      
    }
  }
  `;
  //  reverseTransform(_, { id, regionCode, category, product_id })
  public REVERSE_TRANSFORM = gql`
  mutation reverseTransform($id:String,$regionCode:String,$category:String,$product_id:String,) {
    reverseTransform(id:$id,regionCode:$regionCode,category:$category,product_id:$product_id) {
     status
     message
      
    }
  }
  `;
  public BUY_RESOURCE = gql`
  mutation buyResource($areaCode:String,$quantity:String,$id:String,$resourceCode:String,$regionCode:String,$category:String) {
    buyResource(areaCode:$areaCode,quantity:$quantity,id:$id,resourceCode:$resourceCode,regionCode:$regionCode,category:$category) {
     status
     message
      
    }
  }
`;
  //    buyTicket(_, { id, regionCode, quantity })
  public BUY_TICKET = gql`
mutation buyTicket($id:String,$regionCode:String,$quantity:String) {
  buyTicket(id:$id,regionCode:$regionCode,quantity:$quantity) {
   status
   message
    
  }
}
`;
  // addToStore(store:[StoreInput]):Status

  public REMOVE_EXPIRED = gql`
mutation removeExpired($id:String,$intermediate_id:String,$originRegionCode:String,$areaCode:String,$regionCode:String) {
  removeExpired(id:$id,intermediate_id:$intermediate_id,originRegionCode:$originRegionCode,areaCode:$areaCode,regionCode:$regionCode) {
   status
   message
    
  }
}
`;

  public GET_USER_INTERMEDIATES = gql`
query getIntermediates($id:String,$regionCode:String) {
  getIntermediates(id:$id,regionCode:$regionCode) {
    _id
    payDate
      track{
        areaCode
        regionCode
        origin
      }
      busy
      base_comp
    
    
  }
}
`;

  public GET_ACTIVES_INTERMEDIATES = gql`
query getActivesIntermediates($id:String,$regionCode:String) {
  getActivesIntermediates(id:$id,regionCode:$regionCode) {
    _id
    base_comp
    payDate
    track{
      areaCode
      regionCode
    }
    
  }
}
`;
  public TRACK_USER = gql`
query   trackUser($id:String,$regionCode:String) {
  trackUser(id:$id,regionCode:$regionCode) {
    base_date
    areaCode
    regionCode
    origin
    base_comp
    
}
}
`;
  public REPATRIATE = gql`
mutation   repatriate($id:String,$regionCode:String,$areaCode:String,$currentAreaCode:String,$currentRegionCode:String) {
  repatriate(id:$id,regionCode:$regionCode,areaCode:$areaCode,currentAreaCode:$currentAreaCode,currentRegionCode:$currentRegionCode) {
   status
   message
    
}
}
`;
  //  buyIntermediate(id:"601bdad2d4b9f20ed8d8d6b4",category:"Pro Seller",regionCode:"0",quantity:"2")
  public BUY_INTERMEDIATE = gql`
mutation buyIntermediate($id:String,$category:String,$regionCode:String,$quantity:String) {
  buyIntermediate(id:$id,category:$category,regionCode:$regionCode,quantity:$quantity) {
  status
  message
}
}
`;
  public GET_AREAS = gql`
  query getAreas($id: String!,$regionCode:String) {
    getAreas(id:$id,regionCode:$regionCode) {
   areaCode
   regionCode
      
    }
  }
`;

  // travel(id:String,originAreaCode:String,originRegionCode:String,destinyRegionCode:String,destinyAreaCode:String,duration:String,total:String):Status
  //increaseTime(_, { id,regionCode,minutes,price}) 
  public INCREASE_TIME = gql`
  mutation increaseTime($id:String,$regionCode:String,$minutes:String,$price:String) {
    increaseTime(id:$id,regionCode:$regionCode,minutes:$minutes,price:$price) {
    status  
    message
  }
  }
  `;
  public GET_DISCOUNT = gql`
  query getDiscount($id:String,$regionCode:String) {
    getDiscount(id:$id,regionCode:$regionCode) {
    percentage  
    
  }
  }
  `;
  public GET_BALANCE = gql`
  query getBalance($id:String) {
    getBalance(id:$id) {
    balance  
    
  }
  }
  `;
  public TRAVEL = gql`
mutation travel($id:String,$originAreaCode:String,$originRegionCode:String,$destinyAreaCode:String,$destinyRegionCode:String,$currentAreaCode:String,$currentRegionCode:String,$duration:String) {
  travel(id:$id,originAreaCode:$originAreaCode,originRegionCode:$originRegionCode,destinyAreaCode:$destinyAreaCode,destinyRegionCode:$destinyRegionCode,currentAreaCode:$currentAreaCode,currentRegionCode:$currentRegionCode,duration:$duration) {
  status  
  message
}
}

`;
  public INT_TRAVEL = gql`
mutation intTravel($id:String,$intermediateId:String,$originAreaCode:String,$originRegionCode:String,$destinyAreaCode:String,$destinyRegionCode:String,$currentAreaCode:String,$currentRegionCode:String) {
  intTravel(id:$id,originAreaCode:$originAreaCode,intermediateId:$intermediateId,originRegionCode:$originRegionCode,destinyAreaCode:$destinyAreaCode,destinyRegionCode:$destinyRegionCode,currentAreaCode:$currentAreaCode,currentRegionCode:$currentRegionCode) {
  status  
  message
}
}`
    ;
  //    addToStore(id:String,regionCode:String,areaCode:String,destinyAreaCode:String,destinyRegionCode:String,store:[StoreInput]):Status
  public ADD_TO_STORE = gql`
mutation addToStore($id:String,$regionCode:String,$areaCode:String,$destinyAreaCode:String,$destinyRegionCode:String,$store:[StoreInput],$category:String) {
  addToStore(id:$id,regionCode:$regionCode,areaCode:$areaCode,destinyAreaCode:$destinyAreaCode,destinyRegionCode:$destinyRegionCode,store:$store,category:$category) {
   status
   message
    
  }
}
`;
  public REMOVE_FROM_STORE = gql`
mutation removeFromStore($id:String,$regionCode:String,$areaCode:String,$currentAreaCode:String,$currentRegionCode:String,$idx:String,$category:String) {
  removeFromStore(id:$id,regionCode:$regionCode,areaCode:$areaCode,currentAreaCode:$currentAreaCode,currentRegionCode:$currentRegionCode,idx:$idx,category:$category) {
   status
   message
    
  }
}
`;
  public GET_STAT = gql`
query getStat($id:String,$regionCode:String,$areaCode:String) {
  getStat(id:$id,regionCode:$regionCode,areaCode:$areaCode) {
    sales
    base_stats_date
    purchases
    amountSales
    amountPurchases
    stats{
      time
      quantity
      label
      
    }
    
  }
}
`;
  public GET_US_MARKET = gql`
query getUsMarketDaily($id:String,$regionCode:String) {
  getUsMarketDaily(id:$id,regionCode:$regionCode) {
    market_date
    regionCode
     resourcesSold{
       qty
       total
       resourceCode
     }
     resourcesPurchased{
       qty
       total
       resourceCode
     }
     sales{
       time
       total
       value
     }
     purchases{
       time
       total
       value
     }
    
  }
}
`;

  report = new EventEmitter();
  //removeFromStore(id:String,regionCode:String,areaCode:String,currentAreaCode:String,currentRegionCode:String,idx:String):Status


  //getItem(_, { patternType, patternName, patternItem, regionCode },)
  constructor(private apollo: Apollo, private sessionservice: SessionService, private authservice: AuthService) { }
  getTransformables(id: String, regionCode: String) {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_TRANSFORMATION_DATA,
        variables: {
          id: id,
          regionCode: regionCode,


        },
      })
  }
  getUser() {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_USER,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code')

        },
      })
  }
  setYearReport(reports) {
    this.report.emit(reports)
  }
  getAreas() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_AREAS,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code')
        },
        fetchPolicy: 'no-cache'
      })


  }
  getDiscount() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DISCOUNT,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code')
        },

      })


  }
  getBalance() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_BALANCE,
        variables: {
          id: this.sessionservice.getUserData('_id'),

        },
        fetchPolicy: 'no-cache'

      })


  }

  getTravelDefaults() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_TRAVEL_DEFAULTS,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code')
        },


      })


  }
  dailyReport() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_DAILY_REPORT,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code')
        },
        fetchPolicy: 'no-cache'

      })


  }
  yearReport() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_YEAR_REPORT,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code')
        },
        fetchPolicy: 'no-cache'

      })


  }
  getIntermediateStore(intermediateId, areaCode, regionCode) {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_INTERMEDIATES_STORE,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          intermediateId: intermediateId,
          regionCode: regionCode,
          areaCode: areaCode,


        },
        fetchPolicy: 'no-cache'
      })
  }
  getStat(regionCode, areaCode) {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_STAT,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: regionCode,
          areaCode: areaCode,
        },
        fetchPolicy: 'no-cache'
      })
  }
  getUserMarket() {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_US_MARKET,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),


        },
        fetchPolicy: 'no-cache'
      })
  }
  getGuests(resourceCode, areaCode, regionCode) {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_GUESTS,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: regionCode,
          resourceCode: resourceCode,
          areaCode: areaCode,


        },
        fetchPolicy: 'no-cache'
      })
  }
  renewGuests(resourceCode, areaCode, regionCode) {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.RENEW_GUESTS,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: regionCode,
          resourceCode: resourceCode,
          areaCode: areaCode,


        },
        fetchPolicy: 'no-cache'
      })
  }
  getSellerStore(guestId, areaCode, regionCode) {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_SELLER_STORE,
        variables: {
          guestId: guestId,
          regionCode: regionCode,
          areaCode: areaCode,


        },
        fetchPolicy: 'no-cache'
      })
  }
  getMore42(resourceCode, areaCode, regionCode, ids) {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_MORE_42,
        variables: {
          ids: ids,
          regionCode: regionCode,
          resourceCode: resourceCode,
          areaCode: areaCode,


        },
        fetchPolicy: 'no-cache'
      })
  }
  some
  somePattern(patternItem, patternName) {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.SOME_PATTERNS,
        variables: {

          regionCode: this.sessionservice.getUserData('region-code'),
          patternType: 'for all',
          patternName: patternName,
          patternItem: patternItem,

        },
      })

  }
  getUserResources(id: String) {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_USER_RESOURCES,
        variables: {
          id: id,
          regionCode: this.sessionservice.getUserData('region-code')
        },
      })


  }

  getUserTicketsAndMarket() {
    //alert(this.sessionservice.getUserData('_id'))
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_USER_TICKETS_AND_MARKET,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code')
        },
        fetchPolicy: 'no-cache'
      })


  }
  getUserIntermediates() {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_USER_INTERMEDIATES,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),

        },
        fetchPolicy: 'no-cache'
      })



  }
  getActivesIntermediates() {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_ACTIVES_INTERMEDIATES,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code')
        },
        fetchPolicy: 'no-cache'
      })



  }
  removeExpired(intermediate_id, regionCode, areaCode) {
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.REMOVE_EXPIRED,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          intermediate_id: intermediate_id,
          originRegionCode: this.sessionservice.getUserData('region-code'),
          regionCode: regionCode,
          areaCode: areaCode
        },

      })


  }
  updatePersonalData(data) {
    this.authservice.checkToken();
    var bd=''
    var cell=''
    cell=data.cell
    bd=data.birthdate
    return this.apollo
      .mutate<any>({
        mutation: this.UPDATE_PERSONAL_DATA,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),
          firstname: data.firstname,
          lastname: data.lastname,
          country: data.country,
          timeZone: data.timeZone,
          genre: data.genre,
          cell: cell.toString(),
          birthdate: bd.toString()

        },

      })


  }

  addToStore(destinyAreaCode, destinyRegionCode, data) {
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.ADD_TO_STORE,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          areaCode: this.sessionservice.getUserData('area-code'),
          regionCode: this.sessionservice.getUserData('region-code'),
          destinyAreaCode: destinyAreaCode,
          destinyRegionCode: destinyRegionCode,
          category: this.sessionservice.getUserData('category'),


          store: data
        }, refetchQueries: [
          {
            query: this.GET_USER_RESOURCES,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code')
            },
          },

        ],
      })


  }
  captureOrder(orderID) {
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.CAPTURE_ORDER,
        variables: {
          orderID: orderID,
        }
      })


  }
  removeFromStore(currentAreaCode, currentRegionCode, idx) {
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.REMOVE_FROM_STORE,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          areaCode: this.sessionservice.getUserData('area-code'),
          regionCode: this.sessionservice.getUserData('region-code'),
          currentAreaCode: currentAreaCode,
          currentRegionCode: currentRegionCode,
          category: this.sessionservice.getUserData('category'),

          idx: idx
        }, refetchQueries: [
          {
            query: this.GET_USER_RESOURCES,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code')
            },
          },

        ],
      })


  }

  travel(currentAreaCode, currentRegionCode, destinyAreaCode, destinyRegionCode, duration) {
    //  //console.log(destinyAreaCode, destinyRegionCode, duration)
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.TRAVEL,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          originAreaCode: this.sessionservice.getUserData('area-code'),
          originRegionCode: this.sessionservice.getUserData('region-code'),
          destinyAreaCode: destinyAreaCode,
          destinyRegionCode: destinyRegionCode,
          currentAreaCode: currentAreaCode,
          currentRegionCode: currentRegionCode,
          duration: duration,

        }, refetchQueries: [
          {
            query: this.GET_USER_RESOURCES,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code')
            },
          },
          {
            query: this.GET_TRANSFORMABLES,
            variables: { id: this.sessionservice.getUserData('_id'), regionCode: this.sessionservice.getUserData('region-code') },
          },
          {
            query: this.TRACK_USER,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code'),
            },
          },
        ],

      },
      )


  }
  intTravel(currentAreaCode, currentRegionCode, destinyAreaCode, destinyRegionCode, intermediateId) {
    //  //console.log(destinyAreaCode, destinyRegionCode, duration)
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.INT_TRAVEL,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          originAreaCode: this.sessionservice.getUserData('area-code'),
          originRegionCode: this.sessionservice.getUserData('region-code'),
          destinyAreaCode: destinyAreaCode,
          destinyRegionCode: destinyRegionCode,
          currentAreaCode: currentAreaCode,
          currentRegionCode: currentRegionCode,
          intermediateId: intermediateId


        }, refetchQueries: [
          {
            query: this.GET_USER_RESOURCES,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code')
            },
          },
          {
            query: this.GET_TRANSFORMABLES,
            variables: { id: this.sessionservice.getUserData('_id'), regionCode: this.sessionservice.getUserData('region-code') },
          },

        ],

      },
      )


  }


  buyResource(order_data) {
    // alert(order_data.quantity)
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.BUY_RESOURCE,
        variables: {
          areaCode: order_data.areaCode,
          quantity: order_data.quantity,
          id: this.sessionservice.getUserData('_id'),
          resourceCode: order_data.itemCode,
          regionCode: this.sessionservice.getUserData('region-code'),
          category: this.sessionservice.getUserData('category')
        },
        refetchQueries: [
          {
            query: this.GET_USER_RESOURCES,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code')
            },
          },
          {
            query: this.GET_TRANSFORMABLES,
            variables: { id: this.sessionservice.getUserData('_id'), regionCode: this.sessionservice.getUserData('region-code') },

          },
        ],
      })


  }
  transform(productCode, percentage, id, regionCode) {
    // alert(order_data.quantity)
    this.authservice.checkToken();
    var str_percentage = '';
    str_percentage = percentage


    return this.apollo
      .mutate<any>({
        mutation: this.TRANSFORM,
        variables: {
          productCode: productCode,
          id: id,
          percentage: str_percentage.toString(),
          regionCode: regionCode,

          category: this.sessionservice.getUserData('category')
        },
        refetchQueries: [
          {
            query: this.GET_USER_RESOURCES,
            variables: { id: this.sessionservice.getUserData('_id'), regionCode: this.sessionservice.getUserData('region-code'), },
          },
          {
            query: this.GET_TRANSFORMATION_DATA,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code'),
              patternType: 'for all',
              patternName: this.sessionservice.getUserData('category'),
              patternItem: 'discount',

            },
          },
        ],
      })


  }
  reverseTransform(product_id) {
    // alert(order_data.quantity)

    this.authservice.checkToken();
    //  reverseTransform(_, { id, regionCode, category, product_id })
    return this.apollo
      .mutate<any>({
        mutation: this.REVERSE_TRANSFORM,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),
          category: this.sessionservice.getUserData('category'),
          product_id: product_id,
        },
        refetchQueries: [
          {
            query: this.GET_USER_RESOURCES,
            variables: { id: this.sessionservice.getUserData('_id'), regionCode: this.sessionservice.getUserData('region-code'), },
          },
          {
            query: this.GET_TRANSFORMATION_DATA,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code'),
              patternType: 'for all',
              patternName: this.sessionservice.getUserData('category'),
              patternItem: 'discount',

            },
          },
        ],
      })


  }
  //    buyTicket(_, { id, regionCode, quantity })

  buyTicket(quantity) {
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.BUY_TICKET,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          quantity: quantity,
          regionCode: this.sessionservice.getUserData('region-code'),

        },

      })

  }
  buyIntermediate(quantity) {
    this.authservice.checkToken();
    var qtd = '';
    qtd = quantity
    return this.apollo
      .mutate<any>({
        mutation: this.BUY_INTERMEDIATE,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          quantity: qtd.toString(),
          category: this.sessionservice.getUserData('category'),
          regionCode: this.sessionservice.getUserData('region-code'),

        },
        refetchQueries: [
          {
            query: this.GET_USER_INTERMEDIATES,
            variables: { id: this.sessionservice.getUserData('_id') },
          },

        ],
      })

  }
  getBonus() {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.GET_BONUS,
        variables: {
          owner: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),
        },
      })
  }
  // addBonusToUser(_, { id, regionCode, bonusId, category })
  addBonusToUser(bonusId) {
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.ADD_BONUS_TO_USER,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),
          bonusId: bonusId,
          category: this.sessionservice.getUserData('category')

        },
        refetchQueries: [
          {
            query: this.GET_USER_RESOURCES,
            variables: { id: this.sessionservice.getUserData('_id') },
          },
          {
            query: this.GET_USER_INTERMEDIATES,
            variables: { id: this.sessionservice.getUserData('_id') },
          },
          {
            query: this.GET_BONUS,
            variables: {
              owner: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code'),
            }
          },
          {
            query: this.GET_TRANSFORMATION_DATA,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code'),
              patternType: 'for all',
              patternName: this.sessionservice.getUserData('category'),
              patternItem: 'discount',

            },
          }

        ],
      })
  }

  // THe other functions to list some data need to be refetch here
  repatriate(currentAreaCode, currentRegionCode) {
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.REPATRIATE,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),
          areaCode: this.sessionservice.getUserData('area-code'),
          currentAreaCode: currentAreaCode,
          currentRegionCode: currentRegionCode

        },
        refetchQueries: [
          {
            query: this.GET_USER_RESOURCES,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code')
            },
          },
          {
            query: this.GET_TRANSFORMABLES,
            variables: { id: this.sessionservice.getUserData('_id'), regionCode: this.sessionservice.getUserData('region-code') },

          },
          {
            query: this.TRACK_USER,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code'),
            },
          },
        ],

      })

  }
  trackUser() {
    this.authservice.checkToken();
    return this.apollo
      .watchQuery<any>({
        query: this.TRACK_USER,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),
        },
      })
  }
  increaseTime(price, minutes) {
    this.authservice.checkToken();
    return this.apollo
      .mutate<any>({
        mutation: this.INCREASE_TIME,
        variables: {
          id: this.sessionservice.getUserData('_id'),
          regionCode: this.sessionservice.getUserData('region-code'),
          minutes: minutes,
          price: price


        },
        refetchQueries: [
          {
            query: this.TRACK_USER,
            variables: {
              id: this.sessionservice.getUserData('_id'),
              regionCode: this.sessionservice.getUserData('region-code'),
            },
          },

        ],
      })

  }

}

