import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { SessionService } from '../session-service/session.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DcThemeService } from '../dc-theme/dc-theme.service';
import { Location } from '@angular/common';
import { C } from '@angular/cdk/keycodes';

interface Accesses {
  accessName: string,
  create: boolean,
  read: boolean,
  update: boolean,
  delete: boolean

}

export interface NetWorkStatus {

  message: string,
  up: boolean
}
const LOGIN = gql`
  query Login($data: Auth!) {
    agentLogin(data: $data) {
    ... on Login {
      email
			firstName
			lastName
      token
      isFirstLogin
    }
    ... on AuthStatus {
      status
      message
      
    }
  }
}
`;
const GET_USER_DATA = gql`
query GetUserData {
  getUserData {
    ... on UserData {
      firstName
      email
      lastName
			isFirstLogin
      isAdmin
      accesses {
        accessName
				
        create
        read
        update
        delete
      }
    }
    ... on AuthStatus {
      status
      message
      code
    }
  }
}
`;
const GET_TOKEN_STATUS = gql`
query GetTokenStatus {
  getTokenStatus {
    ... on TokenStatus {
      tokenTimeLeft
    }
    ... on AuthStatus {
      status
      message
      code
    }
  
 }

}
`;

const UPDATE_PASSWORD = gql`
mutation UpdatePassword($data: ChangePasswordinput!) {
  updatePassword(data: $data) {
    status
    message
    code
  }
}
`;

const VALIDATE_LINK = gql`query ValidateLink($token: String) {
  validateLink(token: $token) {
    status
    message
    code
  }
}`

const RESET_FORGOTTEN_PASSWORD=gql`
mutation ResetForgottenPassword($token: String!, $password: String!) {
  resetForgottenPassword(token: $token, password: $password) {
    status
    message
    code
  }
}
`

const FORGOT_PASSWORD = gql`

mutation Mutation($email: String) {
  forgotPassword(email: $email) {
    status
    message
    code
  }
}
`

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public logged = false;
  status: EventEmitter<Boolean> = new EventEmitter();
  reload: EventEmitter<Boolean> = new EventEmitter();
  public setpassword: Boolean = false
  public freshLogin: Boolean = false
  loginData

  unauthorized: Boolean = false
  tokenAlive: Boolean = true
  firstLogin: Boolean = false
  accesses: Accesses[] = []
  updatePasswordStatus: EventEmitter<Boolean> = new EventEmitter();
  networkStatus: EventEmitter<NetWorkStatus> = new EventEmitter();
  isAdmin

  constructor(private apollo: Apollo, private location: Location, private dcthemeservice: DcThemeService, private sessionservice: SessionService, private router: Router, private jwtHelper: JwtHelperService) { }

  async login(data) {

    return this.apollo
      .watchQuery<any>({
        query: LOGIN,
        variables: {
          data: data
        },
        //    fetchPolicy: 'no-cache'
      }).valueChanges.subscribe(({ data }) => {


        if (data.agentLogin.isFirstLogin) {

          this.setpassword = true

        }


        if (data.agentLogin.status) {
          this.status.emit(false)
          return false
        }

        this.freshLogin = true

        this.sessionservice.saveSession(data.agentLogin)

        this.router.navigate([''])

        return true

      }, err => {

        var error = ""
        error = err.toString()
        console.log(error.includes("USER_SUSPENDENDED_0R_BANNED"))



        if (error.includes("USER_SUSPENDENDED_0R_BANNED")) {
          this.networkStatus.emit({ up: false, message: "Your account was suspended.Contact the support team." })
        }

        else {


          this.networkStatus.emit({ up: false, message: "Bad connection with server!!!" })
        }



      });


  }
  getAcesses() {
    return { accesses: this.accesses, isAdmin: this.isAdmin }
  }

  async getTokenStatus() {
    await this.apollo
      .query<any>({
        query: GET_TOKEN_STATUS,

        fetchPolicy: 'cache-first'
      }).toPromise().then(({ data }) => {
        //console.log(data)

        if (data.getTokenStatus.tokenTimeLeft == 0) {
          //console.log("lfttm", data.getTokenStatus.tokenTimeLeft)
          this.tokenAlive = false
        }

      })
  }


  async getTokenTimeLeft() {
    return await this.apollo
      .query<any>({
        query: GET_TOKEN_STATUS,

        // fetchPolicy: 'no-cache'
      }).toPromise()
  }

  forgotPassword(email) {

    return this.apollo
      .mutate<any>({
        mutation: FORGOT_PASSWORD,
        variables: {
          email: email
        }

        //  fetchPolicy: 'cache-first'
      })

  }
  resetForgottenPassword(token,password) {

    return this.apollo
      .mutate<any>({
        mutation: RESET_FORGOTTEN_PASSWORD,
        variables: {
          token: token,
          password:password
        }

        //  fetchPolicy: 'cache-first'
      })

  }

   validateLink(token) {
    return  this.apollo
      .watchQuery<any>({
        query: VALIDATE_LINK,
        variables: {
          token: token
        },

         fetchPolicy: 'no-cache'
      })
  }
  isUnAuthorized() {
    return this.unauthorized
  }
  updatePassword(data) {
    return this.apollo
      .mutate<any>({
        mutation: UPDATE_PASSWORD,
        variables: {
          data: data
        },
        refetchQueries: [
          {
            query: GET_USER_DATA,

          },

        ],
        //  fetchPolicy: 'cache-first'
      }).subscribe(({ data }) => {
        //console.log(data.updatePassword.code)


        if (data.updatePassword.code == 200) {
          this.setpassword = false
          this.freshLogin = true
          //console.log('pass')
          this.updatePasswordStatus.emit(true)
          this.router.navigate([''])
          return false

        }

        this.setpassword = true
        this.updatePasswordStatus.emit(false)


        return false

      }, err => {
        var error = ""
        error = err.toString()
        console.log(error.includes("USER_SUSPENDENDED_0R_BANNED"))



        if (error.includes("USER_SUSPENDENDED_0R_BANNED")) {
          this.networkStatus.emit({ up: false, message: "Your account was suspended.Contact the support team." })
        }

        else {


          this.networkStatus.emit({ up: false, message: "Bad connection with server!!!" })
        }
      });

  }

  async getUserData() {
    await this.apollo
      .query<any>({
        query: GET_USER_DATA,

        fetchPolicy: 'cache-first'
      }).toPromise().then(({ data }) => {
        console.log(data)
        this.accesses = data.getUserData.accesses;
        this.isAdmin = data.getUserData.isAdmin

        if (data.getUserData.isFirstLogin) {
          this.setpassword = true
        }

      })
  }

  async getUserDataPromise(){
    var result = await this.apollo
    .query<any>({
      query: GET_USER_DATA,
      fetchPolicy: 'cache-first'
      // fetchPolicy: 'no-cache'
    }).toPromise()

  // console.log("RESUL TO RESOURCESSSSSS",result)
  return result?.data?.getUserData;
  }
  async cansetPassword() {

    if (this.freshLogin == true) {
      return this.setpassword
    }
    await this.getUserData()

    return this.setpassword
    // return true

  }

  async isLogged() {
    // this.router.navigate(['./dcouts']);
    try {
      var token = this.sessionservice.getUserData('auth-token')

      if (!token) {

        return { allowed: false, action: this.router.navigate(['dcadouts']) }
      }
      //await this.getUserData()
      if (this.freshLogin == false) {
        // //console.log('')
        await this.getTokenStatus()
        if (this.tokenAlive == false) {
          return { allowed: false, action: this.router.navigate(['dcadouts']) }
        }
        await this.getUserData()
        if (this.setpassword) {
          return { allowed: false, action: this.router.navigate(['dcadouts/setpassword']) }
        }
      }


      if (this.setpassword) {
        return { allowed: false, action: this.router.navigate(['dcadouts/setpassword']) }
      }




      return { allowed: true }
    }
    catch (err) {
      //console.log('err')
      return { allowed: false, action: this.router.navigate(['dcadouts']) }
    }



  }

  async checkPermissions(routeData) {
    await this.getUserData()
    const allowed = (this.accesses.findIndex(access => access?.accessName === routeData?.routename && access[routeData?.operation]) >= 0 || this.isAdmin == true ? true : false)
    if (!allowed) {
      this.unauthorized = true
    }
    return allowed
  }
  checkPermissionsForComponent(routeData) {
    const allowed = (this.accesses.findIndex(access => access?.accessName === routeData?.routename && access[routeData?.operation]) >= 0 || this.isAdmin == true ? true : false)
    if (!allowed) {
      this.unauthorized = true
    }
    return allowed
  }
  logout() {
    this.sessionservice.removeUserData();
    this.router.navigate(['dcadouts'])
  }
  getNetworkStatus() {
    return this.networkStatus;
  }
  getStatus() {
    return this.status;
  }
  getUpdateStatus() {
    return this.updatePasswordStatus;
  }
  canReload() {
    return this.reload;
  }
  setReload(value: Boolean) {
    this.reload.emit(value)
  }
  checkToken() {
    var token = this.sessionservice.getUserData('auth-token')
    if (this.jwtHelper.isTokenExpired(token)) {
      this.router.navigate(['dcouts'])
      this.sessionservice.removeUserData();


    }
  }
}
