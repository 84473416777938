import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  public GET_PRODUCTS = gql`
query GetProducts($regionCode: Float!) {
  getProducts(regionCode: $regionCode) {
    ... on Products {
      result {
        _id
        name
        price
        productCode
        regionCode
        src
        combination {
          resourceCode
          quantity
        }
        result {
          resourceCode
          quantity
          transformable
        }
      }
    }
    ... on ProductStatus {
      code
      status
      message
    }
  }

  getResources(regionCode: $regionCode) {
    ... on Resources {
      result {
      
        resourceCode
        name
        maxPrice
      }
    }
    ... on ResourceStatus {
      code
      status
      message
      data {
        id
        regionCode
        resourceCode
        name
        maxPrice
        lifeTime
      }
    }
  }

}
`

  public CREATE_PRODUCT = gql`
mutation CreateProduct($data: ProductInput!) {
  createProduct(data: $data) {
    ... on Product {
      _id
      name
      price
      productCode
      regionCode
      combination {
        resourceCode
        quantity
      }
      result {
        resourceCode
        quantity
        transformable
      }
    }
    ... on ProductStatus {
      code
      status
      message
    }
  }
}
`

  public UPDATE_PRODUCT = gql`
mutation UpdateProduct($data: ProductInputUpdate!, $productCode: Float!) {
  updateProduct(data: $data, productCode: $productCode) {
    ... on Product {
      _id
      name
      price
      productCode
      regionCode
      combination {
        resourceCode
        quantity
      }
      result {
        resourceCode
        quantity
        lifeTime
        transformable
      }
    }
    ... on ProductStatus {
      code
      status
      message
    }
  }
}

`

  public DELETE_PRODUCT = gql`
mutation DeleteProduct($productCode: Float!) {
  deleteProduct(productCode: $productCode) {
    code
    status
    message
  }
}
`

  constructor(private apollo: Apollo) {

  }

  getProducts() {
    return this.apollo
      .watchQuery<any>({
        query: this.GET_PRODUCTS,
        variables: {
          regionCode: 0,
        },
        fetchPolicy: "no-cache"

      })
  }

 async getProductsPromise() {
    var result= await this.apollo
      .query<any>({
        query: this.GET_PRODUCTS,
        variables: {
          regionCode: 0,
        },
        fetchPolicy: "cache-first"

      }).toPromise()

      return result?.data.getProducts?.result
  }

  createProduct(data) {
    return this.apollo
      .mutate<any>({
        mutation: this.CREATE_PRODUCT,
        variables: {
          data: data

        }


      })
  }

  updateProduct(productCode, data) {
    return this.apollo
      .mutate<any>({
        mutation: this.UPDATE_PRODUCT,
        variables: {
          data: data,
          productCode: productCode

        }


      })
  }

  deleteProduct(productCode) {
    return this.apollo
    .mutate<any>({
      mutation: this.DELETE_PRODUCT,
      variables: {
        productCode: productCode

      }


    })
  }

}
