<div class="  row">

  <div class="col-md-6">
    <h6   class="img-text dc-text-success">Cycle:</h6>
    <h6 style="margin-left: 0.4em;" class="img-text">
      
      <countdown [config]="config"></countdown>
    </h6>
    <h6  style="margin-left: 1em;" class="img-text text-danger">Weight:</h6>
    <h6 style="margin-left: 0.4em;" class="img-text">
      
      {{weight}}
    </h6>
  </div>
  <div class=" col-1 col-sm-1 col-md-1 col-lg-1">
    <button (mouseover)=getUserTicketsAndMarket()  nbPopover="{{points}} points" nbPopoverTrigger="hint" nbPopoverPlacement="bottom" id="points-button"
      class="ticket-button">
      <img width="47px" src="assets/images/otherAchievements/points2.png" /></button>
  </div>

  <div class="buttons col-1 col-sm-1 col-md-1 col-lg-1">



    <button (click)="viewResources()" class="  outline cn-button" id="cn-button">Resources</button>

    <div class="cn-wrapper" id="cn-wrapper">
      <ul>
        <li><a (click)="getResourceGroup(0)"><span><br></span>
            <p style="font-size: 14px!important;color: white;">{{resource_quantities[0].quantity}}</p>
            <img style="width: 35px!important; margin-top: -29px!important;" class=" copper img-valign"
              src="assets/images/resources/copper.png" />
          </a>
        </li>
        <li><a (click)="getResourceGroup(1)"><span><br></span>
            <p style="font-size: 14px!important;color: white;">{{resource_quantities[1].quantity}}</p>
            <img style="width: 35px!important; margin-top: -29px!important;" class=" bronze img-valign"
              src="assets/images/resources/bronze.png" />
          </a></li>
        <li><a (click)="getResourceGroup(2)"><span><br></span>
            <p style="font-size: 14px!important;color: white;">{{resource_quantities[2].quantity}}</p>
            <img style="width: 35px!important; margin-top: -29px!important;" class=" silver img-valign"
              src="assets/images/resources/silver2.png" />
          </a></li>
        <li><a (click)="getResourceGroup(3)"><span><br></span>
            <p style="font-size: 14px!important;color: white;">{{resource_quantities[3].quantity}}</p>
            <img style="width: 35px!important; margin-top: -29px!important;" class=" gold img-valign"
              src="assets/images/resources/gold2.png" />
          </a></li>
        <li><a (click)="getResourceGroup(4)"><span><br></span>
            <p style="font-size: 14px!important;color: white;">{{resource_quantities[4].quantity}}</p>
            <img style="width: 35px!important; margin-top: -29px!important;" class="platinum img-valign"
              src="assets/images/resources/patinum2.png" />
          </a></li>
        <li> <a (click)="getResourceGroup(5) ">

            <span><br></span>
            <p style="font-size: 14px!important;color: white;">{{resource_quantities[5].quantity}}</p><img
              style="width: 35px!important; margin-top: -29px!important;" class=" diamond img-valign"
              src="assets/images/resources/diamond.png" />
          </a></li>
        <li><a (click)="getResourceGroup(6)"><span><br></span>
            <p style="font-size: 14px!important;color: white;">{{resource_quantities[6].quantity}}</p><img
              style="width: 35px!important; margin-top: -29px!important;" class=" rubi img-valign"
              src="assets/images/resources/rubi2.png" />
          </a></li>

      </ul>
    </div>
  </div>
 
  <div class=" col-1 col-sm-1 col-md-1 col-lg-1">
    <button (mouseover)=getUserTicketsAndMarket()  nbPopover="Your current location:{{currentLocation}}, your origin:{{originLocation}} " nbPopoverTrigger="hint" nbPopoverPlacement="bottom" id="location-button"
      class="ticket-button">
      <img src="assets/images/otherAchievements/location.png" /></button>
  </div>
  <div class=" col-1 col-sm-1 col-md-1 col-lg-1">
    <button (mouseover)=getUserTicketsAndMarket()   nbPopover="{{time}} minutes" nbPopoverTrigger="hint" nbPopoverPlacement="bottom" id="time-button"
      class="ticket-button">
      <img src="assets/images/travel/timeAch.png" /></button>
  </div>

  <div class=" col-1 col-sm-1 col-md-1 col-lg-1">
    <button (mouseover)=getUserTicketsAndMarket()  nbPopover="{{tickets}} tickets" nbPopoverTrigger="hint" nbPopoverPlacement="bottom" id="ticket-button"
      class="ticket-button1">
      <img src="assets/images/travel/ticket2.png" /></button>
  </div>
  <div class="col-1 col-sm-1 col-md-1 col-lg-1">

    <button (click)="openDialogIntermediates()" style="background: linear-gradient(
  60deg, #ef5350, #e53935);" id="intermediate-button" class="ticket-button">
      <img src="assets/images/travel/intermediate-white.png" /></button>
  </div>







</div>

<ng-template #resourceGroup>

  <h4>{{resource_name}}</h4>
  <table class="table" style=" margin-top: 15px;" mat-table [dataSource]="resource_group_data_source">

    <ng-container matColumnDef="resource">


      <td mat-cell *matCellDef="let element">
        <img class="  img-valign" src="{{element.src}}" />
        <h6 class="img-text">{{element.percent}}%</h6>
      </td>
    </ng-container>
    <ng-container matColumnDef="time">


      <td mat-cell *matCellDef="let element">
        <h6>
          <countdown [config]="{leftTime: element.time, notify: [ 5 ]}" (event)="handleEventMystore($event,element.i)">
          </countdown>
        </h6>
      </td>
    </ng-container>




    <tr mat-row *matRowDef="let row; columns:  displayedColumnsResourceGroup;"></tr>
  </table>
  <mat-paginator class="text-success" style="background-color:white" [pageSizeOptions]="[4, 5, 6]" showFirstLastButtons>
  </mat-paginator>

</ng-template>
<ng-template #intermediates>

  <h4>Intermediates</h4>
  <table class="table" style=" margin-top: 15px;" mat-table [dataSource]="intermediate_data_source">

    <ng-container matColumnDef="code">


      <td mat-cell *matCellDef="let element">
        <h4 style="color: #e53935!important;">{{element.i}}</h4>


      </td>
    </ng-container>
    <ng-container matColumnDef="time">


      <td mat-cell *matCellDef="let element">

        <h6>
          <countdown (event)="handleEventIntermediate($event,element._id,element.areaCode,element.regionCode)"
            [config]="{leftTime: element.time_left, notify: [ 5 ]}">
          </countdown>
        </h6>
      </td>
    </ng-container>




    <tr mat-row *matRowDef="let row; columns:  displayedColumnsIntermediate;"></tr>
  </table>
  <mat-paginator class="text-success" style="background-color:white" [pageSizeOptions]="[4, 5, 6]" showFirstLastButtons>
  </mat-paginator>

</ng-template>