import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntermediatesService {

  constructor() { }
  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }
  timeDiff(dt2, dt1) {

    var date2 = this.convertTZ(dt2, 'Africa/Johannesburg');
    // //console.log(dt1);
    var date1 = this.convertTZ(dt1, 'Africa/Johannesburg');



    var diff = (date2.getTime() - date1.getTime()) / 1000;


    return diff;

  }
  getIntermediateStatus(payDate, base_comp) {

    var time_diff = this.timeDiff(new Date(), payDate);
    // //console.log(time_diff)
    //var base_comp = parseInt(this.user_resources[i].base_comp);
    var base_comp_seconds = base_comp * 3600;
    //alert(time_diff)
    if (time_diff > base_comp_seconds) {
      return { status: false,time_left:0 }
    }
    else {
      var time_left = base_comp_seconds - time_diff;

      return { status: true, time_left: time_left }
    }



  }
}
