import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DcThemeService } from 'app/services/dc-theme/dc-theme.service';
import { ResourceService } from 'app/services/resources/resource.service';
import { SessionService } from 'app/services/session-service/session.service';
import { UserService } from 'app/services/user/user.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort, MatSort } from '@angular/material/sort';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionClient, GRAPHQL_SUBSCRIPTIONS } from 'subscriptions-transport-ws';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { CountdownConfig } from 'ngx-countdown';
import { Subscription } from 'rxjs/Subscription';
import { NbToastrService } from '@nebular/theme';
import { IntermediatesService } from 'app/services/intermediates/intermediates.service';
import { map } from 'rxjs/operators';
import { coerceCssPixelValue } from '@angular/cdk/coercion';
export interface ResourceQuantity {
  quantity: number
}

export interface UserResource {
  _id: string,
  resourceCode: string,
  base_date: string,
  base_comp: string,
  min: Boolean,
  state: Boolean
}
export interface ResourceGroup {
  _id: string,
  i: number,
  src: string,
  percent: number,
  time: number
}
export interface Intermediate {
  _id: string
  i: number,
  time_left: number,
  regionCode: string,
  areaCode: string
}
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];

@Component({
  selector: 'ngx-userachievement',
  templateUrl: './userachievement.component.html',
  styleUrls: ['./userachievement.component.scss']
})
export class UserachievementComponent implements OnInit, AfterViewInit {
  open = false;
  public ticket_qtd;
  show = true;
  tickets = 0;
  todoSubscription: Subscription;
  resource_name = '';
  public NOTIFICATION = gql`
  query notify($channel: String) {
    notify(id: $id,regionCode:$regionCode,action:$action) {
     allowed
     message
      
    }
  }
`;

  constructor(private apollo: Apollo, private intermediateservice: IntermediatesService, private dialog: MatDialog, private sessionservice: SessionService, private userservice: UserService, private resourceservice: ResourceService, private dcthemeservice: DcThemeService, private toastService: NbToastrService) {




  }
  ngAfterViewInit(): void {

  }


  RESOURCES_DATA: ResourceGroup[] = [

  ]
  intermediate_data_source;
  displayedColumnsIntermediate;
  displayedColumnsResourceGroup;
  resource_group: ResourceGroup[] = [

  ]
  currentLocation
  originLocation
  resource_group_data_source;
  allowed: boolean;
  associations
  market_time_left=0
  time
  weight
   points
  resource_quantities: ResourceQuantity[] = [{
    quantity: 0
  },
  {
    quantity: 0
  }, {
    quantity: 0
  },
  {
    quantity: 0
  }, {
    quantity: 0
  }, {
    quantity: 0
  }, {
    quantity: 0
  }];
  user_resources: UserResource[] = [
    {
      _id: '',
      resourceCode: '',
      base_date: '',
      base_comp: '',
      min: false,
      state: true
    }
  ]
  INTERMEDIATE_DATA: Intermediate[] = [
    {
      _id: '',
      i: 0,
      time_left: 123,
      areaCode: '',
      regionCode: ''
    }
  ];

  notificationId = '0F'
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.resource_group_data_source) {
      this.resource_group_data_source.paginator = value;
    }
  }
  @ViewChild(MatPaginator, { static: false })
  set intermediatepaginator(value: MatPaginator) {
    if (this.intermediate_data_source) {
      this.intermediate_data_source.paginator = value;
    }
  }

  config: CountdownConfig 

  @ViewChild("resourceGroup") resourceGroup: TemplateRef<any>;
  @ViewChild("intermediates") intermediates: TemplateRef<any>;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  ngOnInit(): void {
    this.getUserTicketsAndMarket();
 


    //this.dcthemeservice.subscribeNotification()



    this.userservice.somePattern('discount', this.sessionservice.getUserData('category')).valueChanges.subscribe(({ data }) => {
      this.dcthemeservice.setDisctount(data.getItem.value);
    })
    this.dcthemeservice.checkUserPermissions().valueChanges.subscribe(({ data }) => {
      // //console.log(data)
      var is_allowed = data.userAllowed.allowed;
      (is_allowed == 'yes') ? this.allowed = true : this.allowed = false;
      //  //console.log(is_allowed)
    })

    // alert(this.allowed)
  }


  viewResources() {

    var button = document.getElementById('cn-button');
    var wrapper = document.getElementById('cn-wrapper');
    var header = document.getElementById('header');
    var ticketButton = document.getElementById('ticket-button');
    var pointsButton = document.getElementById('points-button');
    var timeButton = document.getElementById('time-button');
    var locationButton = document.getElementById('location-button')


    var intermediateButton = document.getElementById('intermediate-button');


    var cards = document.getElementById('content');
    var buttons = document.getElementsByClassName('buttons')[0];

    if (!this.open) {
      button.innerHTML = "Close"

      ticketButton.classList.add('hide');

      intermediateButton.classList.add('hide');
      locationButton.classList.add('hide');
      pointsButton.classList.add('hide');
      timeButton.classList.add('hide')

      cards.classList.remove('defaultMargin');

      cards.classList.add('changeMargin');
      buttons.classList.add('move-button');

      wrapper.classList.add('opened-nav')

    }
    else {

      button.innerHTML = "Resources"
      buttons.classList.remove('move-button');

      ticketButton.classList.remove('hide');
     
        intermediateButton.classList.remove('hide');
        locationButton.classList.remove('hide');
        pointsButton.classList.remove('hide');
        timeButton.classList.remove('hide')

      cards.classList.add('defaultMargin');
      wrapper.classList.remove('opened-nav');

    }
    this.open = !this.open;
    this.resetArray();

    this.getUserResources();
  }
  resetArray() {
    this.resource_quantities.splice(0, this.resource_quantities.length);
    this.resource_quantities = [{
      quantity: 0
    },
    {
      quantity: 0
    }, {
      quantity: 0
    },
    {
      quantity: 0
    }, {
      quantity: 0
    }, {
      quantity: 0
    }, {
      quantity: 0
    }];
  }



  openDialogResourceGroup() {
    const dialogRef = this.dialog.open(this.resourceGroup, {
      width: '340px',
      //panelClass:'dialog'
    });
    this.displayedColumnsResourceGroup = ['resource', 'time'];
    this.resource_group_data_source = new MatTableDataSource<ResourceGroup>(this.resource_group);

  }

  openDialogIntermediates() {
    // //console.log( this.INTERMEDIATE_DATA )
    this.getIntermediates();
    const dialogRef = this.dialog.open(this.intermediates, {
      width: '340px'
    });

  }
  getIntermediates() {
    this.userservice.getUserIntermediates().valueChanges.subscribe(({ data }) => {
      //  //console.log(data)
      var intermediates = data.getIntermediates;
      var intermediates_list = new Array();
      for (var i = 0; i < intermediates.length; i++) {
        var intermediate_status = this.intermediateservice.getIntermediateStatus(intermediates[i].payDate, intermediates[i].base_comp);

        if (intermediate_status.status) {
          intermediates_list.push({
            _id: intermediates[i]._id,
            i: i,
            time_left: intermediate_status.time_left,
            regionCode: intermediates[i].track.regionCode,
            areaCode: intermediates[i].track.areaCode,

          })
        } else {
          this.userservice.removeExpired(intermediates[i]._id, intermediates[i].track.regionCode, intermediates[i].track.areaCode).subscribe(({ data }) => {
            // this.getIntermediates();
            this.dcthemeservice.showToast('top-right', 'danger', 'One intermediate has expired')

          }, err => {

          })


        }


      }
      this.INTERMEDIATE_DATA = intermediates_list;
      this.displayedColumnsIntermediate = ['code', 'time'];
      //console.log(this.INTERMEDIATE_DATA)
      this.intermediate_data_source = new MatTableDataSource<Intermediate>(this.INTERMEDIATE_DATA);




    })

  }
  getUserTicketsAndMarket() {
    this.userservice.getUserTicketsAndMarket().valueChanges.subscribe(({ data }) => {
      // //console.log(data)
    
//(mouseover)=getUserTicketsAndMarket()
      this.tickets = data.getUserTicketsAndMarket.ticket;
      this.time=data.getUserTicketsAndMarket.time;
      this.weight=data.getUserTicketsAndMarket.marketing.weight;
      this.points=data.getUserTicketsAndMarket.marketing.points;
      var base_comp=data.getUserTicketsAndMarket.marketing.base_comp*86400;
      this.userservice.trackUser().valueChanges.subscribe(({ data }) => {

        this.currentLocation = this.dcthemeservice.getArea(data.trackUser.areaCode).name;

         this.originLocation=this.dcthemeservice.getArea(this.sessionservice.getUserData('area-code')).name;
      })
      this.market_time_left= this.dcthemeservice.getTimeLeft(data.getUserTicketsAndMarket.marketing.base_date,base_comp)
       this.config= {
        leftTime: this.market_time_left,
        formatDate: ({ date, formatStr }) => {
          let duration = Number(date || 0);
    
          return CountdownTimeUnits.reduce((current, [name, unit]) => {
            if (current.indexOf(name) !== -1) {
              const v = Math.floor(duration / unit);
              duration -= v * unit;
              return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
                return v.toString().padStart(match.length, '0');
              });
            }
            return current;
          }, formatStr);
        },
      };
      
      this.ticket_qtd = this.tickets;

    })
  }
  getResourceGroup(index) {
    var indexStr = '';
    indexStr = index;
    this.resource_group = []
    if (this.resource_quantities[index].quantity == 0) {
      this.showToast('top-right', 'danger', 'You do not have a ' + this.dcthemeservice.getResource(index).name.toString() + '!')
    }
    else {
      for (var i = 0; i < this.user_resources.length; i++) {


        if (this.user_resources[i].resourceCode == indexStr.toString()) {



          // //console.log(time_diff)
          var base_comp = parseInt(this.user_resources[i].base_comp);
          var base_date = this.user_resources[i].base_date;
          var resource_status = this.resourceservice.getResourceStatus(base_date, base_comp);
          ////console.log(resource_status.time_left);
          this.resource_name = this.dcthemeservice.getResource(index).name.toString()
          this.resource_group.push(
            { i: i, _id: this.user_resources[i]._id, src: this.dcthemeservice.getResource(index).src.toString(), percent: resource_status.percentage, time: resource_status.time_left }
          )
          // //console.log(this.resource_group);






        }
      }
      this.openDialogResourceGroup();

    }



  }
  showToast(position, status, message) {
    this.toastService.show(
      message,
      'Message!',
      { position, status, preventDuplicates: true });
  }

  getUserResources() {
    this.userservice.getUserResources(this.sessionservice.getUserData('_id')).valueChanges.subscribe(({ data }) => {
      //console.log(data.getUserResources.length);
      this.user_resources = data.getUserResources;
      for (var i = 0; i < data.getUserResources.length; i++) {
        var index = parseInt(data.getUserResources[i].resourceCode)
        //  //console.log(this.resource_quantities[index].quantity)
        this.resource_quantities[index].quantity = this.resource_quantities[index].quantity + 1;
      }

      ////console.log(this.resource_quantities)
      ////console.log(this.user_resources)

    })
  }
  handleEventMystore(e: CountdownEvent, index: any) {
    {


    }
    // this.notify = e.action.toUpperCase();

    if (e.action === 'done') {

      //this.resources[]
      //this.getUserResources();
      //this.getResourceGroup(index);


    }


  }
  handleEventIntermediate(e: CountdownEvent, id: any, areaCode, regionCode) {
    {


    }


    if (e.action === 'done') {

      this.userservice.removeExpired(id, regionCode, areaCode).subscribe(({ data }) => {
        this.getIntermediates();
        this.dcthemeservice.showToast('top-right', 'danger', 'One intermediate has expired')

      }, err => {

      })


    }


  }
}
